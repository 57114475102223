import React from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import logo2 from '../../../../assets/img/logo/logo2.jpeg'
export default function AboutProgram() {
    return (
        <>
            <Header />
            <section className="aboutcera pt182" id="aboutfinolex">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="desktop-television-product">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="section-title">
                                            <h2>About Program </h2>
                                        </div>
                                        <div className="detailabt">
                                            <h3>About Finolex Pipes & Fittings</h3>
                                            <p>
                                            For the last 40 years, Finolex Industries Ltd (FIL) has been India's leading manufacturer of PVC pipes and fittings. Our dedication to quality and excellence has had a significant impact on the sanitation-plumbing and agricultural industries.
                                            With ISO 9001:2015 certifications across our plants, we've earned the trust of our customers, partners, associates, and shareholders. They know they can expect the best from Finolex Pipes because we demand nothing less from ourselves.
                                            Finolex Pipes & Fittings is committed to providing top-quality products and exceptional customer service. Whether you need Pipes & fittings for Plumbing requirements or sanitary & drainage applications, we have a solution that will meet your expectations, across commercial, residential and infrastructural segments.
                                            We're Finolex Industries Limited, India's leading pipe experts.
                                            </p><br />
                                            <h3>About Finolex Pipes & Fittings Super Plumber Loyalty Program</h3>
                                            <p>
                                            Welcome to the Finolex Pipes & Fittings Super Plumber Loyalty Program!
                                            We appreciate your business and want to thank you for choosing us for all of your plumbing needs. With our loyalty program, you can earn points and get rewarded.
                                            Finolex Pipes & Fittings Super Plumber is a loyalty program that brings reward benefits to the associated plumbers on every purchase of Finolex Pipes & Fittings Products from authorized retailers. The purpose of this program is to strengthen the relationship with the plumbers and provide trust, transparency and togetherness.
                                            Finolex Pipes & Fittings firmly believes in nurturing the Plumber community. Finolex Pipes & Fittings Super Plumber loyalty program initiative will empower you to take a big leap towards your success.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <figure className="abtprgrm">
                                            <img src={logo2} alt='' />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
