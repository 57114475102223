import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import { Accordion, AccordionTab } from 'primereact/accordion';
import axios from 'axios';
import * as Constants from "../../../Constants/index"
import Oval from '../../Pages/Loader/CircleLoade';
export default function FaqRetailer() {
    const [loading, setLoading] = useState(false);
    // const [faqUrl, setFaqUrl] = useState('')

    useEffect(() => {
        let token = localStorage.getItem('token');
        // try {
        //     setLoading(true);
        //     axios.get(Constants.baseUrl + 'program/child', {
        //         headers: {
        //             Authorization: `Bearer ${token}`,
        //             "Accept-Language": localStorage.getItem("langauge"),
        //             platform: Constants.pt,
        //             appVersion: Constants.av,
        //             program: Constants.program,
        //             company: Constants.company
        //         }
        //     })
        //         .then((res) => {
        //             if (res.status === 201 && res.data.output) {
        //                 setLoading(false);
        //                 {
        //                     res.data.output.results.map((item) => {
        //                         setFaqUrl(item.faqs)
        //                     })
        //                 }
        //             } else {
        //                 // swal(res.data.msg);
        //             }
        //         })
        // } catch (error) {
        //     console.log(error)
        // }
    }, [])

    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">FAQ's</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            {/* <iframe src={faqUrl} width={'100%'} height={'400px'} frameborder="0"></iframe> */}

                            <div className="all-single-blog rpt">
                                <div className="carrd1 requestbox">
                                    <div className="card-body pad30">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <Accordion activeIndex={0}>
                                                        <AccordionTab header="Q 1. How do I register for the program?">
                                                            <p className="m-0">
                                                                <span>Ans:</span> Download the Finolex Pipes & Fittings Super Plumber Loyalty Program app from Google Play or your sales/marketing team can also register you using their login via the app.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.2 Who is eligible to participate in the Finolex Pipes & Fittings Super Plumber Loyalty Program? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Any Plumber with a valid Aadhaar card and above the age of 18 years. Those who buy eligible products/SKUs from a Finolex-authorized dealer/retailer are eligible to participate in this program.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.3 Is there any fee to join the Finolex Pipes & Fittings Super Plumber Loyalty program?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>There is no such fee for you to get enrolled in this program.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.4  Is the app available on iOS and Google Play store or both?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>The app is available only on Google Play stores.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.5  How do I download the Finolex Pipes & Fittings Super Plumber Loyalty Program?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Android users can simply go to the Google play store and search for the Finolex Pipes & Fittings Super Plumber app and then download the application.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.6 How do I login into my account?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>After completing the registration form your verification process will take 4 to 5 working days as it will be approved by the sales/marketing team. Post verification you will receive your login credentials via SMS which you use to login into the Finolex PIpes & Fittings Super Plumber Loyalty Program app.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.7 How do I earn Points in the Finolex Pipes & Fittings Super Plumber Loyalty Program app ?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>You can earn Points by purchasing eligible Finolex Pipes from authorized dealers/retailers and scanning the QR code displayed on the products. Post successful scanning you can claim the Points in your account.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.8 Is this program for both retail and project orders?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>No. The program is only for Retail orders
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.9 Which products/SKUs are eligible for Points allocation in the Finolex Pipes & Fittings Super Plumber Program?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>The products and their Points value would be enlisted in the Finolex Pipes & Fittings Super Plumber application and the products/SKU would have Finolex Pipes & Fittings Super Plumber logo outside the box to notify that the product is part of the program For more details on eligible products, you may reach us on our program helpdesk at 18002024289 from Mon to Sat between 9.30 am – 5.30 pm.

                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.10 What if the rewards Points in my account are less than the reported purchases made by me?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Connect with our program helpdesk at 18002024289 from Mon to Sat between 9.30 am – 5.30 pm. alternatively, you can also get in touch with your Finolex Pipes sales/marketing team to get more clarity.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.11 What is the value of each reward point in Finolex Pipes & Fittings Super Plumber Loyalty Program?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>One (01) Reward Points = 0.02 Paisa

                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.12 Bonus Points:">
                                                            <p className="m-0">
                                                                1. After authentication, you would receive 10,000 Points as a joining bonus.<br />
                                                                2. The bonus Points cannot be used for the first redemption request<br />
                                                                3. Finolex Pipes Super Plumber can use bonus Points for redemption on the subsequent redemption request.<br />
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.13 Can I transfer my earned Points to another registered Finolex Pipes & Fittings Super Plumber Loyalty Program account?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>No, you cannot transfer your Points to any other registered member.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.14 How do I check my balance Points?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>You can check your balance Points by clicking on the ‘Performance’ tab in the mobile application.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.15 What is validity of points?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>(Three) Years from the date it is prepared
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.16 What is the minimum requirement for redemption?">
                                                            <p className="m-0">
                                                                <span>Ans:</span> A minimum of 5000 Points is required to do the first redemption
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.17 How do I redeem my earned Points?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>There are various options to redeem your accumulated Points from the following:
                                                                <br />
                                                                1. UPI Transfer <br />
                                                                2. Direct Bank Transfer <br />
                                                                3. E-Gift Vouchers <br />
                                                                4. Product Catalogue <br />
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.18  How often can I redeem my accumulated Points?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>You can request redemption throughout the program duration, provided you have sufficient balance Points in your account.{" "}
                                                                <br />
                                                                However, you can also contact the program helpdesk at 18002024289 Mon-Fri (09.30 am to 05.30 pm) to know the current status of your redemption.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.19 How will I know if I have received Points for the purchases submitted by me?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Upon successful uploading of the invoice clear image, the points will be visible as unaudited points in the application and the same will be available for redemption only after approval from the respective retailer.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.20 How much time will it take to receive my redeemed product?">
                                                            <p className="m-0">
                                                                1. UPI transfers will take 24 to 48 hours to get delivered:<br />
                                                                2. Direct bank transfers will take 24 to 48 hours to get delivered<br />
                                                                3. E –Gift vouchers will be given on a real-time basis and will reflect in the app within 24 hr max<br />
                                                                4. Product catalogue will take 3-4 weeks depending on the stick availability and delivery location<br />
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.21 If my Points credited are incorrect or I have not been allocated Points for my participation, then what can I do?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>In such a scenario, you can request your concern from the app or you can also call us at our helpdesk 18002024289 with your registered mobile number and raise a request of your concern. We will look into the concern issue and reply at the earliest.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.22 How do I reach the Finolex Pipes & Fittings Super Plumber program center?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>You can reach us at our program helpline number 18002024289 from 9:30 AM to 5:30 PM. Monday to Saturday
                                                            </p>
                                                        </AccordionTab>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
