// export const baseUrl = 'https://api.finolexsuperplumber.com/api/v1/'  // azure
// export const baseUrl = 'https://apifin.4test.info/api/v1/' //staging
export const baseUrl = 'http://localhost:8081/api/v1/' //local
export const REACT_APP_RECAPTCHA_KEY = '6Lcar3YqAAAAABsW3P1lRwOd_wipyzM9wtg6oD8x'
/**
 * FOR PROD 
export const REACT_APP_RECAPTCHA_KEY = '6LcM-3YqAAAAAEpQfVw0A2LFGFhbh9VedypboVOJ'
*/
export const av = "1.0";
export const pt = "WEBSITE";
export const company = "1"
export const program = "1"  